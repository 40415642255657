<template>
  <div>
    <v-dialog v-model="dialog" scrollable persistent max-width="1250">
      <v-btn icon slot="activator" @click="setItemAndMaxY">
        <v-icon>settings</v-icon>
      </v-btn>
      <v-card>
        <v-card-title>
          <v-icon color="secondary">bluetooth</v-icon>
          <span>&ensp;</span>
          <span class="secondary--text">
            <strong>{{ item.name }}</strong>
          </span>
        </v-card-title>
        <v-card-text>
          <v-layout row>
            <v-flex xs8 offset-xs2 mb-4 class="text-xs-center grey--text mapImage">
              <pointer :position="position" v-if="image" :current="true"></pointer>
              <pointer
                v-for="beacon in beaconsDisplay"
                :key="beacon.name"
                :position="{left: Math.floor(beacon.x * 800 / naturalImage.width) - 12, top: Math.floor(beacon.y * max.y / naturalImage.height) - 12}"
                :current="false"
              ></pointer>
              <img
                :src="image"
                :class="image ? 'dialog_image' : 'noimage dialog_image'"
                width="800"
                id="beacon_setting_image"
                alt
                @click="onClickMap"
                ref="beacon_setting_image"
              >
              <p v-if="!image">{{ $t('appstructure.no_image') }}</p>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs1 class="text-xs-right grey--text pt-2 pr-3">X</v-flex>
            <v-flex xs3>
              <v-text-field
                :value="Number(item.x)"
                suffix="pixel"
                type="number"
                :disabled="!image"
                class="pt-0"
                @input="changeX"
                @keydown="handleKeyDownX"
                :hint="`x should be between 0 and ${naturalImage.width}`"
                min="0"
                :max="naturalImage.width"
              ></v-text-field>
            </v-flex>
            <v-flex xs1 class="text-xs-right grey--text pt-2 pr-3">Y</v-flex>
            <v-flex xs3>
              <v-text-field
                :value="Number(item.y)"
                suffix="pixel"
                type="number"
                :disabled="!image"
                class="pt-0"
                @input="changeY"
                @keydown="handleKeyDownY"
                :hint="`y should be between 0 and ${naturalImage.height}`"
                min="0"
                :max="naturalImage.height"
              ></v-text-field>
            </v-flex>
            <v-flex xs1 class="text-xs-right grey--text pt-2 pr-3">{{ $t('appstructure.height') }}</v-flex>
            <v-flex xs3>
              <v-text-field
                v-model="item.height"
                suffix="cm"
                type="number"
                :disabled="!image"
                class="pt-0"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs1 class="text-xs-right grey--text pt-2 pr-3">{{ $t('appstructure.latitude') }}</v-flex>
            <v-flex xs3>
              <v-text-field v-model="item.latitude" type="number" class="pt-0"></v-text-field>
            </v-flex>
            <v-flex
              xs1
              class="text-xs-right grey--text pt-2 pr-3"
            >{{ $t('appstructure.longitude') }}</v-flex>
            <v-flex xs3>
              <v-text-field v-model="item.longitude" type="number" class="pt-0"></v-text-field>
            </v-flex>
            <v-flex
              xs1
              class="text-xs-right grey--text pt-2 pr-3"
            >{{ $t('appstructure.abs_height') }}</v-flex>
            <v-flex xs3>
              <v-text-field v-model="item.abs_height" suffix="m" type="number" class="pt-0"></v-text-field>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-btn flat color="secondary" @click.stop="onClose">{{ $t('common.close') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn flat color="success" @click.stop="onSave">{{ $t('common.ok') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Pointer from "../../pages/clusters/Pointer";
import PointerMethod from "../../utils/methods/PointerMethod";

export default {
  props: ["selected", "index", "beaconsData", "beaconsDisplay", "image"],
  components: {
    Pointer
  },
  data() {
    return {
      dialog: false,
      map: "",
      naturalImage: {
        width: null,
        height: null,
      },
      default: {
        x: null,
        y: null,
        height: null,
        latitude: null,
        longitude: null,
        abs_height: null
      },
      item: {
        x: null,
        y: null,
        height: null,
        latitude: null,
        longitude: null,
        abs_height: null
      },
      max: {
        x: 800,
        y: 1000
      }
    };
  },
  watch: {
    dialog(val) {
      // action: triggers setY when dialog opens
      this.$nextTick(function() {
        val ? this.setItemAndMaxY() : null;
      });
    }
  },
  methods: {
    compare(value, message, image_height) {
      return value < image_height || message;
    },
    setItemAndMaxY() {
      // On drag we receive a beacon item different than the ones already here (transformed in backend) with no (x,y)
      // if x,y we take the item as it is, otherwise we start with a default one
      this.item = { ...this.selected };

      const image_height = this.$refs.beacon_setting_image.clientHeight;
      this.max.y = this.$refs.beacon_setting_image ? image_height : 1000;

      this.setNaturalImage();

      // need to use nextTick since this.item has just been set above
      this.$nextTick(function() {
        this.onInitPoint();
      });
    },

    setNaturalImage() {
     this.naturalImage.height = this.$refs.beacon_setting_image.naturalHeight;
     this.naturalImage.width = this.$refs.beacon_setting_image.naturalWidth;
    },

    handleKeyDownX(event) {
      const value = Number(event.target.value)
      const invertedValue = Number(value) * 800 / Number(this.naturalImage.width);

      if((invertedValue > this.max.x - 1) && event.keyCode !== 8 || event.keyCode == 189 || event.keyCode === 69) {
        event.preventDefault()
      }
    },

    handleKeyDownY(event) {
      const value = Number(event.target.value)
      const invertedValue = Number(value) * 1000 / Number(this.naturalImage.height);

      if((invertedValue > this.max.y - 1) && event.keyCode !== 8 || event.keyCode == 189 || event.keyCode === 69) {
        event.preventDefault()
      }
    },

    changeX(value) {
      this.item.x = Number(value) > this.naturalImage.width ? this.naturalImage.width : Number(value) < 0 ? 0 : Number(value);

      this.onInitPoint();
    },

    changeY(value) {
      this.item.y = Number(value) > this.naturalImage.height ? this.naturalImage.height : Number(value) < 0 ? 0 : Number(value);

      this.onInitPoint();
    },

    onClose() {
      this.item =  {
        x: null,
        y: null,
        height: null,
        latitude: null,
        longitude: null,
        abs_height: null
      };

      this.$emit("onClose");

      this.dialog = false;
    },

    onSave() {
      this.beaconsData[this.index].x = this.item.x;
      this.beaconsData[this.index].y = this.item.y;
      this.beaconsData[this.index].height = this.item.height;
      this.beaconsData[this.index].latitude = this.item.latitude;
      this.beaconsData[this.index].longitude = this.item.longitude;
      this.beaconsData[this.index].abs_height = this.item.abs_height;

      this.onClose();
    }
  },
  mixins: [PointerMethod]
};
</script>

<style scoped>
.mapImage {
  position: relative;
}
.dialog_image {
  display: block;
}
</style>
